import Vue from "vue";
import Vuex from "vuex";

// import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        pageName: "Dashboard",
        isLogin: localStorage.isLogin || false,
    },
    getters: {},

    mutations: {
        // setLoginStatus(state, status) {
        //     state.isLogin = status;
        // },
        // setUserAuthToken(state, userAuthToken) {
        //     state.userAuthTokenSaved = userAuthToken;
        // },
    },
    actions: {},
    modules: {},
});
