<template>
    <div id="app" class="main-layout" :class="appDir ? 'rtl-dir-app' : ''">
        <SocialBtnComponent />
        <!-- Header -->
        <!-- <AppHeaderFixed
            :showMobileMenu="showMobileMenu"
            @showMobileSideMenu="showMobileSideMenu"
        /> -->
        <!-- Header -->
        <AppHeader
            :showMobileMenu="showMobileMenu"
            @showMobileSideMenu="showMobileSideMenu"
            v-show="showHeader"
        />
        <!-- Page Content -->
        <AppHeaderMobileMenu
            :showMobileMenu="showMobileMenu"
            v-show="showMobileMenu"
            @closeMobileMenu="closeMobileMenu()"
        />
        <router-view style="position: relative" />
        <!-- Footer -->
        <AppFooter />
    </div>
</template>

<script>
// import AppHeaderFixed from "@/components/main-app/AppHeaderFixed.vue";
import AppHeader from "@/components/main-app/AppHeader.vue";
import AppHeaderMobileMenu from "@/components/main-app/AppHeaderMobileMenu.vue";
import AppFooter from "@/components/main-app/AppFooter.vue";
import SocialBtnComponent from "@/components/main-app/SocialBtnComponent.vue";

import bus from "@/functions/eventBus";
export default {
    components: {
        // AppHeaderFixed,
        SocialBtnComponent,
        AppHeader,
        AppHeaderMobileMenu,
        AppFooter,
    },
    created() {
        this.currentLanguage =
            localStorage.getItem("lang") ||
            (localStorage.setItem("lang", "en-US"),
            localStorage.setItem("dir", "ltr")); // Read the language from localStorage

        this.appDir = this.currentLanguage === "ar-EG"; // Set the direction based on the language

        bus.$on("changeDir", (newLanguage) => {
            this.appDir = newLanguage == "ar-EG";
            localStorage.setItem("dir", this.appDir ? "rtl" : "ltr"); // Store the direction in localStorage
        });
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        const storedDirection = localStorage.getItem("dir");
        if (storedDirection) {
            this.appDir = storedDirection === "rtl";
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    name: "App",

    computed: {
        shouldDisplayHeader() {
            return this.scrollPosition <= this.previousScrollPosition;
        },
    },
    methods: {
        showMobileSideMenu() {
            this.showMobileMenu = !this.showMobileMenu;
        },
        closeMobileMenu() {
            this.showMobileMenu = false;
        },
        changeDir() {
            bus.$on("changeDir", () => {
                this.appDir = true;
            });
            this.appDir = !this.appDir;
        },
        handleScroll() {
            this.scrollPosition =
                window.pageYOffset || document.documentElement.scrollTop;

            if (this.scrollPosition > this.previousScrollPosition) {
                this.showHeader = false;
            } else {
                this.showHeader = true;
            }

            this.previousScrollPosition = this.scrollPosition;
        },
    },
    data() {
        return {
            appDir: false,
            scrollPosition: 0,
            previousScrollPosition: 0,
            showHeader: true,
            showMobileMenu: false,
        };
    },
};
</script>

<style lang="scss">
#app {
    min-height: var(--min-height-page);
    overflow: hidden;
}
.rtl-dir-app {
    direction: rtl;
}
</style>
