<template>
    <div class="mobile-sild-menu">
        <span
            v-show="this.$i18n.locale == 'en-US'"
            @click="closeMobileMenu()"
            class="close-mobile-menu"
            >X</span
        >
        <span
            v-show="this.$i18n.locale == 'ar-EG'"
            @click="closeMobileMenu()"
            class="close-mobile-menu-ar"
            >X</span
        >
        <h5>
            <div class="nav-mobile-links m-5">
                <span class="text-info fs-5">Visit</span>
                <ul>
                    <li
                        class="mobile-ul-li"
                        v-for="(link, index) in navLinks"
                        :key="index"
                    >
                        <router-link
                            :to="link.to"
                            :exact-active-class="'active'"
                            class="router-link"
                        >
                            {{ link.name }}
                            <span class="link-underline"></span>
                        </router-link>
                    </li>
                </ul>
                <!-- Select language  -->
            </div>
        </h5>
    </div>
</template>
<script>
export default {
    name: "AppHeaderMobileMenu",
    props: {
        showMobileMenu: {
            type: Boolean,
        },
    },
    methods: {
        closeMobileMenu() {
            this.$emit("closeMobileMenu");
        },
    },
    data() {
        return {
            navLinks: [
                { name: this.$t("links.home"), to: "/" },
                { name: this.$t("links.ourProjects"), to: "/our-projects/1" },
                { name: this.$t("links.ourClients"), to: "/our-partners" },
                { name: this.$t("links.ourProducts"), to: "/our-products" },
                { name: this.$t("links.aboutUs"), to: "/about-us" },
                { name: this.$t("links.contactUs"), to: "/contact-us" },
            ],
            currentLanguage: localStorage.getItem("lang") || "en-US",

            scrollPosition: 0,
        };
    },
};
</script>
<style lang="scss">
.mobile-sild-menu {
    min-height: var(--min-height-page);
    height: 100vh;
    position: fixed;
    // top: var(--app-header-height);
    // width: 63%;
    top: 0px;
    width: 50%;
    z-index: 203;

    // background: rgba(255, 255, 255, 0.895);
    background-color: #f9f9f9fa;
    animation: slidMenuIn 0.5s ease-in-out;
}
.close-mobile-menu {
    background-color: rgba(248, 44, 44, 0.732);
    padding: 5px;
    display: inline-block;
    text-align: center;
    width: 35px;
    font-size: 11pt;
    color: white;
    position: absolute;
    border-radius: 2px;
    top: 0;
    right: -35px;
    cursor: pointer;
}
.close-mobile-menu-ar {
    background-color: rgba(248, 44, 44, 0.732);
    padding: 5px;
    display: inline-block;
    text-align: center;
    width: 35px;
    font-size: 11pt;
    color: white;
    position: absolute;
    border-radius: 2px;
    top: 0;
    left: -35px;
    cursor: pointer;
}
.mobile-ul-li {
    margin: 15px;
    width: 100%;
}
.router-link {
    position: relative;
    display: inline-block;
    margin: 0 10px;
    color: var(--project-links-color);
    color: rgb(73, 73, 73);
    text-decoration: none;

    .link-underline {
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: rgb(255, 85, 37);
        transition: width 0.9s ease-in-out;
    }

    &.active {
        color: rgb(255, 85, 37);
    }

    &.active .link-underline {
        width: 100%;
    }

    &:hover {
        // width: 100%;
        color: rgb(255, 85, 37);
    }
}

@keyframes slidMenuIn {
    from {
        transform: translate(-100px);
        opacity: 0;
    }
    to {
        transform: translate(0px);
        opacity: 1;
    }
}
</style>
