<template>
    <div>
        <ScrollAnimation :animatedClass="'animate-enter-two'">
            <el-divider
                :content-position="
                    this.$i18n.locale == 'en-US' ? 'left' : 'right'
                "
            >
                <span class="services-section-title">{{
                    $t("services.title")
                }}</span>
            </el-divider>
        </ScrollAnimation>
        <section class="services-section">
            <!-- <h2 class="services-heading">{{ $t("services.title") }}</h2> -->
            <ScrollAnimation :animatedClass="'animate-enter'">
                <div class="services-container">
                    <div
                        v-for="(service, index) in $t('services.services')"
                        :key="index"
                        class="service-card"
                    >
                        <h3 class="service-title">{{ service.title }}</h3>
                        <div class="service-icon">
                            <img
                                class="service-icon-image-header"
                                width="100"
                                :src="
                                    require(`../../assets/imgs/services-section/${service.imageName}`)
                                "
                                alt=""
                            />
                        </div>
                        <div class="service-details">
                            <p class="service-description">
                                {{ service.description }}
                            </p>
                        </div>
                        <div class="service-icon">
                            <img
                                class="service-icon-image"
                                width="100"
                                src="../../assets/imgs/services-section/services-section-01.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
        </section>
    </div>
</template>

<script>
import ScrollAnimation from "@/components/general-components/ScrollAnimation.vue";
export default {
    name: "ServicesSection",
    components: {
        ScrollAnimation,
    },
};
</script>

<style>
/* Custom CSS styles for the services section */
.services-section {
    margin: 25px 0;
    padding: 30px 15px;
    background-color: #f4f4f4;
    margin-bottom: 0;
    overflow: hidden;
}
.services-section-title {
    color: #fc653b;
    margin: auto;
    padding: 25px 20px;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: capitalize;
    /* border: 1px solid rgba(255, 255, 255, 0.5098039216); */
    /* background-color: rgba(255, 255, 255, 0.85); */
    font-size: 17pt;
    width: 100%;
    text-align: center;
}
.services-heading {
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
}

.services-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(263px, 1fr));
    gap: 12px;
    /* max-width: 1200px; */
    margin: 0 auto;
}

.service-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
    /* background-image: url("../../assets/imgs/services-section/services-section-01.png"); */
    /* background-size: 100%;
    background-position: 50% 8%;
    background-repeat: no-repeat; */
    position: relative;
}

.service-card:hover {
    transform: translateY(-5px);
    background-color: rgb(233, 233, 233);
}

.service-icon {
    /* Add styles for the service icon or image here */
    font-size: 36px;
    color: #007bff;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.service-icon-image {
    width: 33%;
    margin-top: 34px;
    position: absolute;
    bottom: 20px;
}
.service-icon-image-header {
    width: 97%;
    margin-top: 11px;
    min-height: 175px;
    max-height: 175px;
}

.service-title {
    font-size: 13pt;
    color: #ffffff;
    background-color: #d99500;
    width: 97%;
    max-width: 100%;
    min-height: 122px;
    max-height: 63px;
    margin: 0px auto;
    padding: 10px;
    border-top-left-radius: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.service-description {
    font-size: 16px;
    color: #555;
    /* text-align: start; */
    min-height: 267px;
    text-align: justify;
    /* word-break: break-all; */
}
</style>
