<template>
    <div
        class="app-header-container"
        id="app-header-id"
        :class="{ scrolled: shouldChangeBackgroundColor }"
    >
        <nav
            class="app-header"
            :style="{
                height: isScrollingUp
                    ? 'var(--app-header-height-scroll)'
                    : 'var(--app-header-height)',
            }"
        >
            <!-- logo -->
            <div class="logo-project-name-container">
                <router-link to="/">
                    <div class="logo">
                        <img
                            class="sig-logo-img"
                            src="@/assets/imgs/companies/sig-logo-blue.png"
                            alt="logo"
                        />
                    </div>
                </router-link>
                <!-- <div class="project-name-container">
                    <h4 class="project-name">{{ $t("projectName") }}</h4>
                </div> -->
            </div>
            <!-- links container -->
            <div class="nav-links">
                <span v-for="(link, index) in navLinks" :key="index">
                    <router-link
                        :to="link.to"
                        :exact-active-class="'nav-link-active'"
                        >{{ link.name }}
                    </router-link>
                </span>
                <!-- Select language  -->
            </div>
            <span class="lang-box-container">
                <span class="lang-icon-container">
                    <span
                        @click="selectSiteLang()"
                        class="lang-select-icon-main"
                        ><i class="fa-solid fa-globe"></i
                    ></span>
                    <img
                        v-if="currentLanguage === 'en-US' && showSelectLang"
                        @click="changeLanguage()"
                        class="lang-icon"
                        src="../../assets/imgs/svg-icons/lang-ar.svg"
                        alt=""
                    />
                    <img
                        v-else-if="
                            currentLanguage === 'ar-EG' && showSelectLang
                        "
                        @click="changeLanguage()"
                        class="lang-icon"
                        src="@/assets/imgs/svg-icons/lang-en.svg"
                        alt=""
                    />
                </span>
            </span>
            <i
                @click="showMobileSideMenu()"
                class="fa-solid fa-ellipsis-vertical mobile-sidebar-icon"
            ></i>
        </nav>
    </div>
</template>
<script>
import bus from "@/functions/eventBus";
export default {
    name: "AppHeader",
    props: {
        showMobileMenu: {
            type: Boolean,
        },
    },
    components: {},
    methods: {
        selectSiteLang() {
            this.showSelectLang = !this.showSelectLang;
        },
        showMobileSideMenu() {
            this.$emit("showMobileSideMenu");
        },
        changeLanguage() {
            const newLanguage =
                this.currentLanguage === "en-US" ? "ar-EG" : "en-US";

            localStorage.setItem("lang", newLanguage);

            this.currentLanguage = newLanguage;

            bus.$emit("changeDir", newLanguage);

            location.reload(); // Reload the page
        },
        handleScroll() {
            const newScrollPosition =
                window.pageYOffset || document.documentElement.scrollTop;

            this.isScrollingUp = newScrollPosition < this.scrollPosition;
            this.scrollPosition = newScrollPosition;
        },
    },
    data() {
        return {
            navLinks: [
                { name: this.$t("links.home"), to: "/" },
                {
                    name: this.$t("links.ourProjects"),
                    to: "/our-projects/1",
                },
                { name: this.$t("links.ourClients"), to: "/our-partners" },
                { name: this.$t("links.ourProducts"), to: "/our-products" },
                { name: this.$t("links.aboutUs"), to: "/about-us" },
                { name: this.$t("links.contactUs"), to: "/contact-us" },
            ],
            currentLanguage: localStorage.getItem("lang") || "en-US",

            scrollPosition: 0,
            isScrollingUp: false,
            showSelectLang: false,
        };
    },
    computed: {
        shouldChangeBackgroundColor() {
            return this.scrollPosition > 50;
        },
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>
<style lang="scss">
.mobile-sidebar-icon {
    display: none !important;
    cursor: pointer;
}
.lang-box-container {
    align-self: start;
    width: 65px;
    padding: 4px;
}
.lang-select-icon-main {
    color: #ff5525;
    color: white;
}
@media (max-width: 786px) {
    .lang-select-icon-main {
        position: absolute;
        top: 23px;
    }
    .mobile-sidebar-icon {
        display: block !important;
        padding: 5px;
        // padding-top: 17px;
        margin: 5px;
        color: white;
    }
    .space-top-for-header {
        display: none;
    }
    .lang-box-container {
        display: inline-block;
        width: 100%;
        padding: 0px 15px;
        text-align: end;
        align-self: center;
    }
}
.app-header-container {
    &.scrolled .app-header {
        background-color: rgba(255, 255, 255, 0.8);
        background-color: #59d0f591;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;

        height: var(--app-header-height-scroll);
        & a {
            color: gray;
            color: white;
            font-size: 13pt;
        }

        & .sig-logo-img {
            width: 68px;
            height: 36px;
        }
        /* Add any additional styles for the header when scrolled beyond 50px */
    }
    & .lang-box-container .lang-box-container {
        display: inline-block;
        width: 100%;
        padding: 0px 15px;
        text-align: end;
        align-self: center;
    }
}
.app-header {
    width: var(--app-header-width);
    height: var(--app-header-height);
    padding: 10px 20px;
    border-bottom: 1px solid var(--app-header-border-color);
    position: fixed;
    top: 0;
    z-index: 99;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 18px;

    transition: height 0.3s ease;
}
.logo-project-name-container {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    width: 200px;
}
.logo {
    & a {
        display: inline-block;
        width: 100%;
    }
    width: 100%;
    & .sig-logo-img {
        padding: 4px;
        width: 140px;
        height: 75px;
        border-radius: 5px;
        background-color: white;
    }
}

@media screen and (max-width: 1200px) {
    .app-header-container.scrolled
        .app-header
        .lang-box-container
        .lang-select-icon-main {
        position: absolute;
        top: 13px;
    }
}
@media screen and (max-width: 768px) {
    .logo {
        & .sig-logo-img {
            width: 90px;
            // height: 60px;
        }
    }
    .app-header-container.scrolled
        .app-header
        .lang-box-container
        .lang-select-icon-main {
        top: 13px;
    }
}
.project-name {
    color: var(--project-name-color);
    margin: 0 10px;
    letter-spacing: 2px;
    font-weight: 600;
    font-style: italic;
    text-transform: capitalize;
    text-decoration: underline;
}
.nav-links {
    & a {
        padding: 5px;
        margin: 0 5px;
        font-size: 13pt;
        text-decoration: none;
        color: var(--project-links-color);
        color: rgb(73, 73, 73);
        color: white;
        &:hover {
            color: rgb(255, 85, 37);
            // margin: 10px;
        }
        &.nav-link-active {
            border-bottom: 1px solid rgb(255, 85, 37);
            color: rgb(255, 85, 37);
            // margin: 10px;

            position: relative;
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 2px;
                background-color: rgb(255, 85, 37);
                // transition: all 0.9s ease-in-out;
                animation: borderActive 1s ease-in-out;
            }
            &.nav-link-active::after {
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .nav-links a {
        font-size: 9pt;
    }
}
@media screen and (max-width: 786px) {
    .nav-links a {
        display: none !important;
    }
    .lang-icon-container {
        justify-self: end;
    }
}

.lang-icon-container {
    // position: relative;
    width: 50px;
    & .lang-icon {
        width: 35px;
        height: 35px;
        cursor: pointer;
        top: 49px;
        z-index: 99999;
        position: absolute;
        border-radius: 50%;
        border: 1px solid gray;
        background-color: white;
    }
}
@keyframes borderActive {
    from {
        border-bottom: none;
        width: 0;
    }
    to {
        border-bottom: 2px solid rgb(255, 85, 37);
        width: 100%;
    }
}
</style>
