<template>
    <div class="contact-info-sec">
        <ScrollAnimation :animatedClass="'animate-enter-three'">
            <h2 class="contact-info-title" id="contact-info-sec-id">
                {{ $t("contacts.title") }}
            </h2>
        </ScrollAnimation>

        <!-- contact details uls body 1 -->
        <!-- <div class="contact-info-body"> -->
        <ScrollAnimation :animatedClass="'animate-enter-two'">
            <ul class="contact-info-company-details-container">
                <li
                    v-for="contact in $t('contactInfo')"
                    :key="contact.id"
                    class="contact-info-company-details-li"
                >
                    <div class="contact-info-company-details-img-container">
                        <!-- company image logo -->
                        <img
                            width="40"
                            class="contact-info-company-details-img"
                            :src="
                                require(`@/assets/imgs/companies/${contact.companyImage}`)
                            "
                            alt="company"
                        />
                    </div>
                    <div>
                        <!-- company name -->
                        <div class="contact-info-company-name">
                            <a :href="contact.companyLink" target="_blank"
                                >{{ contact.companyName }}
                            </a>
                        </div>

                        <!-- phone -->
                        <div class="contact-info-company-mob-container">
                            <img
                                class="contact-info-company-label-img"
                                width="20"
                                src="@/assets/imgs/contact-info/contact-tele-02.png"
                                alt=""
                            />
                            <p class="contact-info-company-mob">
                                {{ contact.companyPhone }}
                            </p>
                        </div>
                        <!-- address -->
                        <span class="contact-info-company-address">
                            <img
                                class="contact-info-company-label-img"
                                width="20"
                                src="@/assets/imgs/contact-info/contact-address-03.png"
                                alt=""
                            />
                            <p>{{ contact.companyAddress }}</p>
                        </span>
                    </div>
                </li>
            </ul>
        </ScrollAnimation>

        <!-- </div> -->

        <div class="contact-info-body-2">
            <ScrollAnimation :animatedClass="'animate-enter-two'">
                <div class="logo">
                    <div class="contact-info-links-container">
                        <img
                            src="@/assets/imgs/contact-info/sig-logo-white.png"
                            alt=""
                        />
                        <p class="contact-us-paragraph">
                            {{ $t("aboutUs.socialsNavText") }}
                        </p>
                        <router-link
                            class="text-decoration-none text-secondary"
                            to="/about-us"
                        >
                            {{ $t("contacts.readMore") }} ...</router-link
                        >
                    </div>
                </div>
            </ScrollAnimation>
            <ScrollAnimation :animatedClass="'animate-enter'">
                <div>
                    <form @submit.prevent>
                        <div class="mb-3">
                            <label
                                for="exampleFormControlInput1"
                                class="form-label"
                                >{{ $t("contacts.email") }}</label
                            >
                            <input
                                dir="ltr"
                                type="email"
                                class="form-control"
                                :class="
                                    isSubmitDisabled
                                        ? 'invalid-input'
                                        : 'valid-input'
                                "
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                                v-model="email"
                                required
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleFormControlTextarea1"
                                class="form-label"
                                >{{ $t("contacts.message") }}</label
                            >
                            <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                :placeholder="$t('contacts.yourMessage')"
                                v-model="message"
                                required
                            ></textarea>
                        </div>
                        <el-button
                            :disabled="isSubmitDisabled"
                            class="btn btn-success"
                            :plain="true"
                            @click="open2"
                        >
                            {{ $t("send") }}</el-button
                        >
                    </form>
                </div>
            </ScrollAnimation>
        </div>

        <ScrollAnimation :animatedClass="'animate-enter-three'">
            <div class="contact-us-location-container">
                <div class="mapouter">
                    <div class="gmap_canvas">
                        <iframe
                            style="width: 100%"
                            class="gmap_iframe mt-5"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            src="https://maps.google.com/maps?width=300&amp;height=628&amp;hl=en&amp;q=SIG El Shahawy Investment Group&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        ></iframe>
                    </div>
                </div>
            </div>
        </ScrollAnimation>
        <ScrollAnimation :animatedClass="'animate-enter-two'">
            <div>
                <ScrollAnimation :animatedClass="'animate-enter-two'">
                    <el-divider content-position="left">{{
                        $t("contacts.FollowUs")
                    }}</el-divider>
                </ScrollAnimation>
                <!-- facebook -->
                <div class="social-icons-container">
                    <a
                        class="contact-info-link"
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=100063706794706"
                    >
                        <img
                            class="contact-info-link contact-info-link-img-x"
                            src="../../assets/imgs/designs/social-icons/facebook.png"
                            alt=""
                        />
                    </a>
                    <!-- twitter -->
                    <a
                        class="contact-info-link"
                        target="_blank"
                        href="https://twitter.com/WaelELshahawy1"
                    >
                        <img
                            class="contact-info-link contact-info-link-img-x"
                            src="../../assets/imgs/designs/social-icons/twitter-x5265.logowik.com.jpg"
                            alt=""
                        />
                    </a>
                    <!-- instagram  -->
                    <a
                        class="contact-info-link"
                        target="_blank"
                        href="https://www.instagram.com/elshahawywael/"
                    >
                        <img
                            class="contact-info-link contact-info-link-img-x"
                            src="../../assets/imgs/designs/social-icons/Instagram_logo.png"
                            alt=""
                        />
                    </a>

                    <!-- linked-in -->
                    <a
                        class="contact-info-link"
                        target="_blank"
                        href="https://www.linkedin.com/feed/?trk=eml-email_pymk_01-header-70-home"
                    >
                        <img
                            class="contact-info-link contact-info-link-img-x"
                            src="../../assets/imgs/designs/social-icons/linked-in.png"
                            alt=""
                        />
                    </a>
                    <!-- whatsapp -->
                    <a
                        class="contact-info-link"
                        target="_blanket"
                        href="https://wa.me/+201285066197?text=hello"
                    >
                        <i
                            class="fa-brands fa-whatsapp social-icons-fixed-icons whats-app whats-contact-info-link"
                        ></i
                    ></a>
                </div>
            </div>
        </ScrollAnimation>
        <!-- social & maps body 2 -->
    </div>
</template>

<script>
import ScrollAnimation from "@/components/general-components/ScrollAnimation.vue";
export default {
    components: {
        ScrollAnimation,
    },
    computed: {
        isSubmitDisabled() {
            return !(
                this.email &&
                this.message &&
                this.isEmailValid(this.email)
            );
        },
    },
    data() {
        return {
            sentMsg: this.$t("contacts.messageSent"),
            email: "",
            message: "",
        };
    },
    methods: {
        open2() {
            this.$message({
                message: this.sentMsg,
                type: "success",
            });
            this.email = "";
            this.message = "";
        },
        isEmailValid(email) {
            // Use a regular expression to check if the email is valid
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
    },
};
</script>
<style lang="scss">
@import url("//unpkg.com/element-ui@2.15.13/lib/theme-chalk/index.css");
// new design
.contact-info-company-details-container {
    width: 100%;
    min-height: 400px;
    // margin: 20px auto;
    color: white;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 12px;
    flex-wrap: wrap;
}
.contact-info-company-details-li {
    width: 350px;
    height: 177px;
    display: grid;
    grid-template-columns: 70px 1fr;
    grid-template-rows: auto;
    align-items: center;
    justify-items: start;
}
.contact-info-company-details-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-info-company-details-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: white;
    padding: 5px;
}
.contact-info-company-address {
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-info-company-name {
    // border: 1px solid rgb(255, 255, 255);
}
.contact-info-company-name a {
    display: inline-block;
    min-width: 98%;
    font-size: 15pt;
    color: white;
    text-decoration: underline;
    text-align: center;
    &:hover {
        color: #fc653b;
    }
}
.contact-info-company-label-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgb(229, 229, 229);
    margin: 10px;
}
.contact-info-company-mob-container {
    display: flex;
}
.contact-info-company-mob {
    padding: 5px;
    margin-top: 5px;
    font-size: 11pt;
    direction: ltr !important;
}
// ========================
hr {
    background-color: #fc653b !important;
}
.contact-info-sec {
    padding: 25px 6px;
    background-color: #11223b;
    background-color: #043041;
}
.contact-info-title {
    color: rgb(252, 101, 59);
    margin-top: 0;
    padding: 25px 20px;
    /* width: 50%; */

    border-bottom: thin solid currentColor;
}
// ====================
.contact-info-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & ul {
        width: 350px;
        padding: 10px;
    }
}
.contact-info-body-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    & > div {
        width: 45%;
        min-height: 300px;
        // border: thin solid currentColor;
    }
}
@media screen and (max-width: 766px) {
    .contact-info-body-2 {
        // flex-direction: column;
        & > div {
            width: 100%;
            min-height: 200px;
        }
    }
}
.contact-info-links-container {
    padding: 10px;
    margin: 10px;
    width: 93%;
}
.contact-info-link {
    & i {
        margin: 0 10px;
    }
    & i.facebook {
        font-size: 20pt;
        color: #3b5998;
    }
    & i.twitter {
        font-size: 20pt;
        color: #55acee;
    }
    & i.google-plus {
        font-size: 20pt;
        color: #dd4b39;
    }
}
.contact-info-link-img-x {
    max-width: 100%;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin: 0 8px;
    &:hover {
        scale: 1.7;
    }
}
.contact-us-paragraph {
    margin: 0px;
    margin-top: 28px;
    font-size: 10pt;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: white;
    text-align: justify;
}
.contact-info-logo {
    width: 30px;
    height: 30px;
    margin: 0 15px;
    border-radius: 50%;
    padding: 5px;
    background-color: whitesmoke;
}
.contact-info-list-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
}
.agent-company-name-contact {
    color: white !important;
}
.contact-info-value {
    color: #777;
    color: #ff8e48;
    color: #c8c8c8;
    font-size: 14px;
    // text-decoration: underline;
}
.form-label {
    color: #ff8e48;
    color: white;
}
// =========
@keyframes coloring {
    from {
        opacity: 0;
        color: black;
    }
    to {
        opacity: 1;
        color: rgb(54, 118, 157);
        // transform: translate(0%);
    }
}
.mapouter {
    position: relative;
    text-align: right;
    // width: 300px;
    // height: 628px;
}
.gmap_canvas {
    overflow: hidden;
    background: none !important;
    // width: 300px;
    // height: 628px;
}
.gmap_iframe {
    width: 100% !important;
    margin: 0 auto !important;
    height: auto !important;
}
.social-icons-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-control.invalid-input {
    border: 1px solid red !important;
    outline: red;
}

.valid-input {
    border: 2px solid green;
    outline: green;
    background-color: rgba(0, 128, 0, 0.475);
}
.whats-contact-info-link {
    font-size: 24pt;
    &:hover {
        scale: 1.7;
    }
}
</style>
