import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
// import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "HomeView",
        component: HomeView,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/our-projects",
        name: "OurProjectsPage",
        component: () => import("@/views/our-projects/OurProjectsPage.vue"),
        children: [
            {
                path: ":id",
                name: "CompanyShow",
                component: () =>
                    import(
                        "@/components/our-companies-projects/CompanyShow.vue"
                    ),
            },
        ],
    },
    {
        path: "/our-partners",
        name: "OurPartnersPage",
        component: () => import("@/views/our-partners/OurPartnersPage.vue"),
    },
    {
        path: "/our-products",
        name: "OurProductsPage",
        component: () => import("@/views/our-products/OurProductsPage.vue"),
    },
    {
        path: "/about-us",
        name: "AboutUsPage",
        component: () => import("@/views/about-us/AboutUsPage.vue"),
    },
    {
        path: "/contact-us",
        name: "ContactUsPage",
        component: () => import("@/views/contact-us/ContactUsPage.vue"),
    },
    {
        path: "/test",
        name: "TestPage",
        component: () => import("@/views/TestPage.vue"),
    },
    {
        path: "/animation",
        name: "LoadingAnimation",
        component: () => import("@/components/animations/LoadingAnimation.vue"),
    },
    {
        path: "/:notfound",
        name: "PageNotFound",
        component: () => import("@/views/PageNotFound.vue"),
    },
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    // scrollBehavior(to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition;
    //     }
    //     return { x: 0, y: 0 };
    // },
    // =======
});
// router.beforeEach((to, from, next) => {
//     window.scrollTo(0, 0);
//     next();
// });

export default router;
