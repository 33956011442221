<template>
    <section class="projects-companies-section">
        <ScrollAnimation :animatedClass="'animate-enter-two'">
            <h2 class="projects-companies-title" id="projects-companies">
                {{ $t("projects.title") }}
            </h2>
        </ScrollAnimation>
        <h4
            class="projects-companies-subtitle m-4 p-3 text-decoration-underline text-white"
        >
            - {{ $t("projects.subtitle") }}
        </h4>

        <ScrollAnimation :animatedClass="'animate-enter'">
            <ul class="projects-companies-list">
                <li
                    v-for="(projects, index) in $t('projects.projects')"
                    :key="index"
                    class="projects-companies-list-item"
                >
                    <div class="projects-company-details">
                        <span
                            :class="
                                $i18n.locale == 'en-US'
                                    ? 'projects-company-name'
                                    : 'projects-company-name-ar'
                            "
                            >{{ projects.title }}</span
                        >
                        <span class="projects-company-parent"
                            >({{ projects.description }})</span
                        >
                    </div>
                    <div class="projects-list-separating"></div>
                    <img
                        class="project-list-main-img"
                        :src="
                            require('@/assets/imgs/projects/' +
                                projects.imageName)
                        "
                        alt=""
                    />
                </li>
            </ul>
        </ScrollAnimation>
    </section>
</template>

<script>
import ScrollAnimation from "@/components/general-components/ScrollAnimation.vue";
export default {
    name: "OurProjects",
    components: {
        ScrollAnimation,
    },
};
</script>

<style lang="scss">
/*
*/
.project-list-main-img {
    width: 100%;
    min-height: 250px;
    max-height: 200px;
    height: 350px;
    min-width: 229px;
}
.projects-companies-section {
    background-color: #f5f5f5;
    background-color: #043041;
    padding: 30px;
    /* border-radius: 10px; */
}

.projects-companies-title {
    color: rgb(252, 101, 59);
    margin-top: 0;
    padding: 25px 20px;
    /* width: 50%; */

    border-bottom: thin solid currentColor;
}
@media screen and (max-width: 600px) {
    .projects-companies-title {
        font-size: 12pt;
        margin: 20px 0px;
    }
}
.projects-companies-list {
    padding: 5px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}

.projects-companies-list-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* margin: 10px; */
    margin-bottom: 15px;
    position: relative;
    flex-direction: column-reverse;
    width: 27%;
    min-height: 400px;
    min-width: 222px;
    background-color: white;
    padding: 0px 0px 10px 0px;
    border-radius: 10px;
    overflow: hidden;
    &:hover {
        transform: translateY(-5px);
        // background-color: rgb(40, 95, 114);
    }
}
@media screen and (max-width: 600px) {
    .projects-companies-list {
        gap: 20px;
    }
    .projects-companies-list-item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: 10px;
        margin-bottom: 15px;
        position: relative;
        flex-direction: column-reverse;
        width: 50%;
        min-height: 450px;
        min-width: 350px;
        &:hover {
            transform: translateY(10px);
            scale: 1.09;
        }
    }
}
.projects-list-separating {
    border-bottom: 1px solid darkgrey;
    width: 53%;
    margin: 15px 73px;
    position: absolute;
    bottom: -14px;
}

.projects-company-logo {
    width: 40px;
    height: 40px;
    margin: 0 15px;
    border-radius: 50%;
}

.projects-company-details {
    display: flex;
    flex-direction: column;
    min-height: 304px;
}

.projects-company-name {
    color: #4b8fb7;
    font-weight: 600;
    padding: 9px 27px;
    font-size: 11pt;
    background: #d5ebff;
    box-shadow: 1px 1px 3px currentColor;
    transform: translateX(-15px);
    min-height: 86px;
    text-align: center;

    display: grid;
    align-items: center;
    margin-top: 15px;
}
.projects-company-name-ar {
    color: #4b8fb7;
    font-weight: 600;
    padding: 9px 27px;
    font-size: 10pt;
    background: #d5ebff;
    box-shadow: 1px 1px 3px currentColor;
    transform: translateX(15px);
    min-height: 86px;
    text-align: center;

    display: grid;
    align-items: center;
    margin-top: 15px;
}

.projects-company-parent {
    color: #777;
    font-size: 13px;
    min-height: 160px;
    padding: 10px;
    text-align: justify;
    padding: 3px 15px;
    padding: 12px 15px;
}
</style>
