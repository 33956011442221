<template>
    <div class="app-footer-container">
        <p class="copyright-para">
            {{ $t("footer") }}
        </p>
    </div>
</template>
<script>
export default {
    name: "AppFooter",
};
</script>
<style lang="scss">
.app-footer-container {
    height: var(--app-footer-height);
}
.copyright-para {
    text-align: center;
    font-size: 10pt;
    color: var(--secondary-color);
    padding: 15px;
}
</style>
