<template>
    <div class="home-page">
        <SliderComponents style="z-index: 5" />
        <WeAreSectionComponent />
        <VisionComponent />
        <OurPartnersHomeSection />

        <StaticsComponent />
        <!-- <DrWaelMedia /> -->
        <ServicesSectionComponent />
        <OurProjects />
        <div class="respirator"></div>
        <ContactInfoComponent />
    </div>
</template>

<script>
import SliderComponents from "@/components/home/SliderComponents.vue";
import WeAreSectionComponent from "@/components/home/WeAreSectionComponent.vue";
import VisionComponent from "@/components/home/VisionComponent.vue";
import OurPartnersHomeSection from "@/components/home/OurPartnersHomeSection.vue";

import StaticsComponent from "@/components/home/StaticsComponent.vue";
// import DrWaelMedia from "@/components/home/DrWaelMedia.vue";
import ServicesSectionComponent from "@/components/home/ServicesSectionComponent.vue";
import OurProjects from "@/components/home/OurProjects.vue";
import ContactInfoComponent from "@/components/home/ContactInfoComponent.vue";

export default {
    name: "HomeView",
    components: {
        SliderComponents,
        WeAreSectionComponent,
        VisionComponent,
        OurPartnersHomeSection,
        StaticsComponent,
        // DrWaelMedia,
        ServicesSectionComponent,
        OurProjects,
        ContactInfoComponent,
    },
    created() {},
    data() {
        return {
            projectName: "projectName",
        };
    },
    methods: {},
};
</script>
<style lang="scss">
.home-page {
    min-height: 100vh;
    // min-height: var(--min-height-page);
    // background-color: aliceblue;
}
.respirator {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 3px;
    background: whitesmoke;
    border-top: 1px solid whitesmoke;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        180deg,
        rgb(255, 255, 255) 4%,
        rgba(236, 248, 255, 0.954) 98%
    );
}
</style>
