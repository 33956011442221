<template>
    <div>
        <div class="we-are-section-container">
            <!-- left title -->
            <div class="we-are-section-header-container">
                <h4 class="we-are-section-header">
                    {{ $t("weAreSection.title") }}
                </h4>
                <h2 class="we-are-section-subheader">
                    {{ $t("weAreSection.subtitle") }}
                </h2>
                <p v-show="isImageVisible" class="we-are-section-para">
                    {{ $t("weAreSection.para") }}
                </p>
            </div>
            <!-- div of images -->
            <div
                v-show="isImageVisible"
                class="we-are-section-images-container"
            >
                <img
                    class="we-are-section-image"
                    ref="image"
                    src="@/assets/imgs/designs/technology.png"
                    alt=""
                    :class="{ animate: isImageVisible }"
                />
            </div>
        </div>
        <div class="we-are-section-btn-container">
            <button
                @click="goToAboutPage()"
                class="we-are-section-btn"
                :class="{ animate: isImageVisible }"
            >
                {{ $t("more") }}
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: "WeAreSectionComponent",
    data() {
        return {
            isImageVisible: false,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll() {
            const image = this.$refs.image;
            if (!image) return; // Guard clause to handle undefined image reference
            const imagePosition = image.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;

            if (imagePosition - windowHeight < 0) {
                this.isImageVisible = true;
            }
        },
        goToAboutPage() {
            this.$router.push("/about-us");
        },
    },
};
</script>
<style lang="scss">
.we-are-section-container {
    width: 100%;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // border-bottom: 1px solid rgb(203, 203, 203);
    flex-direction: row;
    overflow: hidden;
}

.we-are-section-header-container {
    width: 50%;
    background-image: url(@/assets/imgs/backgrounds/we-section-bg-1.svg);
    color: red;
    letter-spacing: 3px;
    text-transform: capitalize;
}
.we-are-section-header {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 50px 20px 5px;
    &::after {
        content: " ";
        display: inline-block;
        width: 119px;
        border-bottom: 2px solid #f24f228e;
        margin: 0 30px;
    }
}
.we-are-section-subheader {
    color: #003e7e;
    margin: 10px 50px;
    font-weight: 700;
    width: 86%;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.we-are-section-para {
    font-family: "Lato", sans-serif;
    font-weight: 500;
    font-size: 12pt;
    color: rgb(79, 79, 79);
    word-spacing: 3px;
    letter-spacing: 1px;
    width: 90%;
    animation: opacity 2.5s ease-in-out;
    z-index: 5;
    text-align: justify;
}

.we-are-section-images-container {
    width: 50%;
    margin-top: 76px;
    opacity: 0.4;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
        // animation: transitionFromUp 1.5s ease-in-out;
    }
}
.we-are-section-image {
    width: 100%;
    width: 55%;
    border-radius: 20px;
    z-index: 0;
}
.we-are-section-btn {
    padding: 10px;
    margin: 15px 10px;
    width: 200px;
    backdrop-filter: blur(10px);
    border-radius: 5px;
    border: none;
    color: #ff6d6d;
    background-color: transparent;
    box-shadow: 1px 2px 3px rgb(154, 154, 154);
    overflow: hidden;

    &:hover {
        background-color: #ff6d6d;
        color: white;
        width: 300px;
    }
}
// ====================================================
@media screen and (max-width: 768px) {
    .we-are-section-container {
        flex-direction: column;
    }
    .we-are-section-header-container {
        width: 100%;
    }
    .we-are-section-para {
        width: 90%;
    }
    .we-are-section-images-container {
        width: 87%;
        margin-bottom: 27px;
    }
    .we-are-section-btn-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 21px;
        & button {
            width: 40%;
        }
    }
}
.animate {
    animation: transitionFromUp 2.5s ease-in-out;
    backdrop-filter: blur(10px);
}
@keyframes transitionFromUp {
    from {
        transform: translate(600px);
        opacity: 0.2;
        // width: 0px;
    }
    to {
        transform: translate(0);
        opacity: 1;
        // width: 100%;
    }
}
@keyframes moreWidth {
    from {
        width: 0px;
    }
    to {
        width: 200px;
    }
}
@keyframes opacity {
    from {
        opacity: 0;
        transform: translate(-500px);
    }
    to {
        opacity: 1;
        transform: translate(0px);
    }
}
</style>
