<template>
    <div class="static-section-container">
        <ScrollAnimation :animatedClass="'animate-enter-two'">
            <h1 class="static-section-heder">
                {{ $t("staticTitle") }}
            </h1>
        </ScrollAnimation>
        <ScrollAnimation :animatedClass="'animate-enter-three'">
            <p class="static-section-heder-paragraph">
                {{ $t("staticsSubtitle") }}
            </p>
        </ScrollAnimation>
        <div class="projects-static-container">
            <div
                v-for="box in $t('statics')"
                :key="box.id"
                class="static-years-of-experience static-box"
            >
                <ScrollAnimation
                    :animatedClass="
                        box.id % 2 == 0 ? 'animate-enter' : 'animate-enter-two'
                    "
                >
                    <h3 class="static-box-title">{{ box.title }}</h3>
                    <h1 class="text-white fs-1 mt-5 static-box-value">
                        {{ box.value }}
                    </h1>
                </ScrollAnimation>
            </div>
            <!-- <ScrollAnimation :animatedClass="'animate-enter-two'">
                <div class="static-projects static-box">
                    <h3 class="static-box-title">projects</h3>
                    <h1 class="text-white fs-1 mt-5 static-box-value">522 +</h1>
                </div>
            </ScrollAnimation>
            <ScrollAnimation :animatedClass="'animate-enter'">
                <div class="static-employees static-box">
                    <h3 class="static-box-title">employees</h3>
                    <h1 class="text-white fs-1 mt-5 static-box-value">215 +</h1>
                </div>
            </ScrollAnimation>
            <ScrollAnimation :animatedClass="'animate-enter-two'">
                <div class="static-clients static-box">
                    <h3 class="static-box-title">clients</h3>
                    <h1 class="text-white fs-1 mt-5 static-box-value">433 +</h1>
                </div>
            </ScrollAnimation> -->
        </div>
    </div>
</template>
<script>
import ScrollAnimation from "@/components/general-components/ScrollAnimation.vue";
export default {
    name: "StaticsComponent",
    components: {
        ScrollAnimation,
    },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");
.static-section-container {
    background-color: rgb(21, 87, 103);
    background-color: rgb(0 71 88);
}
.static-section-heder {
    padding: 28px;
    padding-top: 70px;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px 22px lightgreen;
    text-transform: uppercase;
}
.static-section-heder-paragraph {
    font-size: 1.23rem;
    color: white;
    text-align: center;
    font-weight: 700;
    text-transform: capitalize;
    text-shadow: 1px 1px 22px lightgreen;
    text-decoration: underline;
    text-decoration-color: #83cfff;
    text-decoration-style: solid;
    text-decoration-thickness: 8px;
    margin-top: 32px;
}
.projects-static-container {
    margin: 0 auto;
    padding: 35px 40px;
    background-color: rgb(21, 87, 103);
    background-color: #043041;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 480px;
}
.static-box {
    width: 290px;
    height: 200px;

    border: 1px solid #777;
    border-bottom: none;
    border-right: none;
    border-top-left-radius: 50px;

    padding: 10px;
    margin: 5px;
    margin: 10px auto;

    text-align: center;
    box-shadow: -1px -2px 2px rgb(99 184 204);
}
.static-box-title {
    height: 59px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    margin-top: 20px;
    text-shadow: 1px 1px 22px lightgreen;
    text-transform: uppercase;
}
.static-box-value {
    font-family: "Audiowide", cursive;
    text-shadow: 1px 1px 22px lightgreen;
}
</style>
