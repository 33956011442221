<template>
    <div>
        <!-- <div class="vision-mission-title-container">
            <h2 class="vision-mission-title">
                {{ $t("homePage.VisionAndMission") }}
            </h2>
        </div> -->
        <ScrollAnimation :animatedClass="'animate-enter-two'">
            <el-divider
                :content-position="
                    this.$i18n.locale == 'en-US' ? 'left' : 'right'
                "
            >
                <span class="vision-mission-title">{{
                    $t("homePage.VisionAndMission")
                }}</span>
            </el-divider>
        </ScrollAnimation>
        <section class="vision-mission-section">
            <div class="bg-img-1st"></div>
            <div class="content-2nd">
                <ScrollAnimation :animatedClass="'animate-enter'">
                    <div class="vision-container m-4">
                        <!-- =================    vision =============== -->
                        <div class="vision-mission-subTitle">
                            <span class="vision-mission-img-container"
                                ><img
                                    class="vision-mission-icon-vision"
                                    src="../../assets/imgs/designs/vision-icon-1.jpg"
                                    alt=""
                            /></span>
                            <span>
                                {{ $t("homePage.vision") }}
                            </span>
                        </div>
                        <p class="vision-mission-paragraph">
                            {{ $t("homePage.visionParagraph") }}
                        </p>
                    </div>
                </ScrollAnimation>
                <!-- ===========    mission      ============= -->
                <ScrollAnimation :animatedClass="'animate-enter'">
                    <div class="mission-container m-4">
                        <div class="vision-mission-subTitle">
                            <span class="vision-mission-img-container"
                                ><img
                                    class="vision-mission-icon-mission"
                                    src="../../assets/imgs/designs/mission-1.png"
                                    alt=""
                            /></span>
                            <span>{{ $t("homePage.mission") }}</span>
                        </div>
                        <p class="vision-mission-paragraph content-2nd">
                            {{ $t("homePage.missionParagraph") }}
                        </p>
                    </div>
                </ScrollAnimation>

                <!-- ============     management     ============ -->
                <ScrollAnimation :animatedClass="'animate-enter'">
                    <div class="mission-container m-4">
                        <div class="vision-mission-subTitle">
                            <span class="vision-mission-img-container">
                                <img
                                    class="vision-mission-icon-management-image"
                                    src="../../assets/imgs/designs/management-icon.png"
                                    alt=""
                            /></span>
                            <span>{{ $t("homePage.RoleOfDirectors") }}</span>
                        </div>
                        <p class="vision-mission-paragraph">
                            {{ $t("homePage.RoleOfDirectorsParagraph") }}
                        </p>
                    </div>
                </ScrollAnimation>
            </div>
        </section>
    </div>
</template>
<script>
import ScrollAnimation from "@/components/general-components/ScrollAnimation.vue";
export default {
    name: "VisionComponent",
    components: {
        ScrollAnimation,
    },
};
</script>
<style lang="scss">
.vision-mission-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    padding: 1%;
    padding-left: 0;
    padding-bottom: 2px;
    min-height: 400px;
    height: 100%;
    justify-content: center;
    justify-items: center;
}

@media screen and (max-width: 780px) {
    .vision-mission-section {
        grid-template-columns: 100%;
        grid-template-rows: 371px auto;
    }
}
.bg-img-1st {
    // background-image: url("../../assets/imgs/vision-mission-sections/vision-mission-01.png");
    // background-image: url("../../assets/imgs/designs/our-projects/slider-our-projects-1.jpg");
    background-image: url("../../assets/imgs/designs/engineering/engineering-7.jpg");
    background-image: url("../../assets/imgs/vision-mission-sections/vistion-mission-img-02.jpg");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    // border-top-right-radius: 15px;
    box-shadow: -15px -7px 5px #f4f4f4;
    // margin: 0px 12px;
    opacity: 1.5;
}
@media screen and (max-width: 768px) {
    .bg-img-1st {
        opacity: 0.9;
    }
}

.vision-mission-title-container {
}
.vision-mission-title {
    color: #fc653b;
    margin: auto;
    padding: 25px 20px;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: capitalize;
    /* border: 1px solid rgba(255, 255, 255, 0.5098039216); */
    /* background-color: rgba(255, 255, 255, 0.85); */
    font-size: 17pt;
    width: 100%;
    text-align: center;
}
.vision-mission-subTitle {
    color: rgb(73, 73, 73);
    color: #4192e5;
    text-shadow: 1px 2px 3px rgb(187, 187, 187);
    text-transform: uppercase;
    text-align: center;
    font-size: 15pt;
    margin: 17px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.vision-mission-paragraph {
    background-color: #ececec94;
    color: #777;
    text-align: start;
    padding: 17px;
    word-spacing: 2px;
    border-radius: 10px;
    box-shadow: 2px 3px 3px lightgray;
    text-align: justify;
}
.content-2nd {
    background-color: white;
}
@media screen and (max-width: 780px) {
    .vision-mission-title {
    }
}
.vision-mission-image {
    // position: fixed;
}
.vision-mission-img-container {
    // margin: 5px;
    // & > img {
    //     width: 60px;
    // }
}
.vision-mission-icon-vision {
    margin: 10px;
    width: 50px;
}
.vision-mission-icon-mission {
    width: 75px;
}
.vision-mission-icon-management-image {
    // width: 50px;
    width: 34px;
    margin: 0px 22px 0px 6px;
}
</style>
