<template>
    <div class="social-icons-fixed-component-container">
        <div @click="toggleSocialIcons()" class="social-icons-fixed-container">
            <img
                class="social-icons-fixed-img"
                src="../../assets/imgs/designs/social-media.jpg"
                alt=""
            />
        </div>
        <div v-if="showSocialIcons" class="social-icons-fixed-icons-container">
            <!-- facebook -->
            <a
                target="_blanket"
                href="https://www.facebook.com/profile.php?id=100063706794706"
                ><i
                    class="fa-brands fa-facebook social-icons-fixed-icons facebook"
                ></i
            ></a>

            <!-- instagram -->
            <a target="_blanket" href="https://www.instagram.com/elshahawywael">
                <img
                    class="social-icons-fixed-icons"
                    src="../../assets/imgs/designs/social-icons/Instagram_logo.png"
                    alt=""
                />
            </a>

            <!-- whatsapp -->
            <a target="_blanket" href="https://wa.me/+201285066197?text=hello">
                <i
                    class="fa-brands fa-whatsapp social-icons-fixed-icons whats-app"
                ></i
            ></a>

            <!-- linked-in -->
            <a
                target="_blanket"
                href="https://www.linkedin.com/feed/?trk=eml-email_pymk_01-header-70-home"
            >
                <img
                    class="social-icons-fixed-icons"
                    src="../../assets/imgs/designs/social-icons/linked-in.png"
                    alt=""
                />
            </a>
            <!-- twitter X -->
            <a target="_blanket" href="https://twitter.com/WaelELshahawy1">
                <img
                    style="border-radius: 50%"
                    class="social-icons-fixed-icons"
                    src="../../assets/imgs/designs/social-icons/twitter-x5265.logowik.com.jpg"
                    alt=""
                />
            </a>
        </div>
    </div>
</template>
<script>
export default {
    name: "SocialBtnComponent",
    data() {
        return {
            showSocialIcons: false,
        };
    },
    methods: {
        toggleSocialIcons() {
            this.showSocialIcons = !this.showSocialIcons;
        },
    },
};
</script>
<style lang="scss">
.social-icons-fixed-component-container {
    position: relative;
    overflow: hidden;
    width: 100px;
}
.social-icons-fixed-container {
    background-color: orangered;
    // color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    position: fixed;
    bottom: 37px;
    margin: 20px;
    z-index: 999999;

    cursor: pointer;
    &:hover {
        scale: 1.6;
    }
    .social-icons-fixed-img {
        border-radius: 50%;
        scale: 1;
    }
}
.social-icons-fixed-icons-container {
    background-color: white;
    background-color: rgb(229 229 229 / 94%);
    min-width: 400px;
    max-height: 30px;
    margin: 0 50px;
    padding: 0 10px;
    position: fixed;
    bottom: 66px;
    z-index: 9999;
    animation: showSocialIcons 0.5s ease-in-out;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 23px;
    gap: 10px;
    & a {
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        &:hover {
            scale: 1.3;
        }
    }
}
@media screen and (max-width: 500px) {
    .social-icons-fixed-icons-container {
        min-width: 300px;
        max-width: 380px;
        padding: 0px 37px;
        & a {
            width: 45px;
            height: 45px;
        }
    }
    .social-icons-fixed-icons {
        font-size: 8pt;
    }
}
.social-icons-fixed-icons {
    font-size: 30pt;
    &.facebook {
        color: cornflowerblue;
    }
}
.whats-app {
    color: rgb(28, 164, 28);
}
@keyframes showSocialIcons {
    from {
        opacity: 0;
        transform: translateX(-100px);
        width: 5px;
    }
    to {
        opacity: 1;
        width: 300px;
        transform: translateX(0px);
    }
}
</style>
