var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-header-container",class:{ scrolled: _vm.shouldChangeBackgroundColor },attrs:{"id":"app-header-id"}},[_c('nav',{staticClass:"app-header",style:({
            height: _vm.isScrollingUp
                ? 'var(--app-header-height-scroll)'
                : 'var(--app-header-height)',
        })},[_c('div',{staticClass:"logo-project-name-container"},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"logo"},[_c('img',{staticClass:"sig-logo-img",attrs:{"src":require("@/assets/imgs/companies/sig-logo-blue.png"),"alt":"logo"}})])])],1),_c('div',{staticClass:"nav-links"},_vm._l((_vm.navLinks),function(link,index){return _c('span',{key:index},[_c('router-link',{attrs:{"to":link.to,"exact-active-class":'nav-link-active'}},[_vm._v(_vm._s(link.name)+" ")])],1)}),0),_c('span',{staticClass:"lang-box-container"},[_c('span',{staticClass:"lang-icon-container"},[_c('span',{staticClass:"lang-select-icon-main",on:{"click":function($event){return _vm.selectSiteLang()}}},[_c('i',{staticClass:"fa-solid fa-globe"})]),(_vm.currentLanguage === 'en-US' && _vm.showSelectLang)?_c('img',{staticClass:"lang-icon",attrs:{"src":require("../../assets/imgs/svg-icons/lang-ar.svg"),"alt":""},on:{"click":function($event){return _vm.changeLanguage()}}}):(
                        _vm.currentLanguage === 'ar-EG' && _vm.showSelectLang
                    )?_c('img',{staticClass:"lang-icon",attrs:{"src":require("@/assets/imgs/svg-icons/lang-en.svg"),"alt":""},on:{"click":function($event){return _vm.changeLanguage()}}}):_vm._e()])]),_c('i',{staticClass:"fa-solid fa-ellipsis-vertical mobile-sidebar-icon",on:{"click":function($event){return _vm.showMobileSideMenu()}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }