<template>
    <div>
        <vue-intersect @enter="onEnter" @leave="onLeave">
            <div :class="isElementEntered ? animatedClass : ''">
                <slot></slot>
            </div>
        </vue-intersect>
    </div>
</template>
<script>
export default {
    name: "ScrollAnimation",
    props: {
        animatedClass: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isElementEntered: false,
        };
    },
    methods: {
        onEnter() {
            this.isElementEntered = true;
        },
        onLeave() {
            // this.isElementEntered = false;
        },
    },
};
</script>
<style lang="scss">
.animate-enter {
    animation: fadeInAnsTranslate 1s;
}
.animate-enter-two {
    animation: opacityAnimationAndTranslate 1s;
}
.animate-enter-three {
    animation: rotateYAnimation 2s;
}

@keyframes fadeInAnsTranslate {
    from {
        opacity: 0;
        transform: translateX(250px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes opacityAnimationAndTranslate {
    from {
        opacity: 0.2;
        transform: translateX(-250px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes rotateYAnimation {
    from {
        opacity: 0;
        transform: translate3d(36px, 19px, -23px);
        transform: rotateY(135deg);
    }
    to {
        opacity: 1;
        transform: translate3d(36px, 19px);
        transform: rotateY(0deg);
    }
}
</style>
